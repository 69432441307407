// Add cookie reading function
export function getCookie(name: string, clear: boolean = true): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const cookie = parts.pop()?.split(";").shift() ?? null;
    if (clear) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
    return cookie;
  }
  return null;
}
