import React, { useCallback, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Avatar, Button, Checkbox, Divider, FormControlLabel, Paper, TextField, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Apis } from "../api/apis";
import { AuthApi } from "../api/impl/auth";
import { sharedApiConfig } from "../config";
import { ApplicationThemeProvider } from "../components/theme";
import { EphemeralStorageProvider, PersistentStorageProvider, Session } from "../components/auth/session";
import { LoaderButton } from "../components/common/LoaderButton";
import { useApplication } from "../state/applicationstate";
import GoogleButton from "react-google-button";

type LoginFooterProps = {
  forgotPasswordLink?: string;
  signupLink?: string;
};

function LoginFooter(props: LoginFooterProps) {
  const { forgotPasswordLink, signupLink } = props;
  return (
    <Grid container>
      <Grid item xs>
        (
        {forgotPasswordLink && (
          <Link href={forgotPasswordLink} variant="body2">
            Forgot password?
          </Link>
        )}
        )
      </Grid>
      <Grid item>
        (
        {signupLink && (
          <Link href={signupLink} variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        )}
        )
      </Grid>
    </Grid>
  );
}

type Props = {
  forgotPasswordLink?: string;
  signupLink?: string;
  canRemember?: boolean;
};

function MultiProviderLogin(props: Props) {
  const { forgotPasswordLink, signupLink, canRemember } = props;
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [oauthError, setOAuthError] = useState<string | null>(searchParams.get("oauth_error"));
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(Session.getDefaultStorageProvider().name === PersistentStorageProvider.name);
  const [showEmailPasswordLogin, setShowEmailPasswordLogin] = useState(false);
  const navigate = useNavigate();

  const { redirectUrl } = location.state ?? {};

  const handleSubmit = useCallback(async () => {
    try {
      setOAuthError(null);
      setLoading(true);
      const authApi = new AuthApi(sharedApiConfig.endpoints.auth);
      const { token, refreshToken } = await authApi.login(email, password);
      Session.shared().set(token, refreshToken);
      Apis.shared().setAuthentication(token, refreshToken);
      setLoading(false);
      setError("");
      navigate(redirectUrl ?? "/", { state: { token, refreshToken } });
    } catch (e: any) {
      setLoading(false);
      setError("Please check email and password");
    }
  }, [navigate, password, email, redirectUrl]);

  const handleGoogleLogin = useCallback(() => {
    const targetPath = redirectUrl ? new URL(redirectUrl, window.location.origin).pathname : undefined;
    window.location.href = Apis.shared().auth.googleLoginUrl(targetPath);
  }, [redirectUrl]);

  const toggleRememberMe = () => {
    let providerName: string = EphemeralStorageProvider.name;
    let newRemember = !remember;
    if (newRemember) {
      providerName = PersistentStorageProvider.name;
    }
    Session.changeStorageProvider(Session.shared(), providerName);
    setRemember(newRemember);
  };

  return (
    <Paper sx={{ height: "100%" }}>
      <Box
        sx={{ display: "flex", direction: "column", justifyContent: "center", alignItems: "center", height: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Container maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                Sign in
              </Typography>
              <Divider sx={{ mt: 2, mb: 2, width: "100%" }} />
              <Typography component="h3" variant="h6" sx={{ mb: 1 }}>
                Sign in to the Canucci Analysis platform.
              </Typography>
              {canRemember && (
                <FormControlLabel
                  onChange={toggleRememberMe}
                  checked={remember}
                  sx={{ mt: 1, mb: 0 }}
                  control={<Checkbox color="primary" />}
                  label="Stay signed in"
                />
              )}
              <Divider sx={{ mt: 2, mb: 3, width: "100%" }} />
              <Box component="form" noValidate sx={{ mt: 0, mb: 2, width: "100%" }}>
                <GoogleButton onClick={handleGoogleLogin} style={{ width: "100%" }} />
              </Box>
              <Box sx={{ mt: 1, mb: 0, width: "100%" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={() => setShowEmailPasswordLogin(!showEmailPasswordLogin)}
                >
                  Sign in with email and password
                </Button>
              </Box>
              {showEmailPasswordLogin && (
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    error={!!error}
                    onChange={(e: any) => {
                      setEmail(e.target.value);
                    }}
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    error={!!error}
                    onChange={(e: any) => {
                      setPassword(e.target.value);
                    }}
                    autoComplete="current-password"
                  />
                  <LoaderButton
                    loading={loading}
                    title="Sign In"
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    size="large"
                  />
                </Box>
              )}
              <Box component="form" noValidate sx={{ mt: 1 }}>
                {(error || oauthError) && (
                  <Typography sx={{ mt: 2 }} color="error">
                    Login failed: {error || oauthError}
                  </Typography>
                )}
                {(forgotPasswordLink || signupLink) && <LoginFooter />}
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Paper>
  );
}

function PasswordOnlyLogin(props: Props) {
  const { forgotPasswordLink, signupLink, canRemember } = props;
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(Session.getDefaultStorageProvider().name === PersistentStorageProvider.name);
  const navigate = useNavigate();

  const { redirectUrl } = location.state ?? {};

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const authApi = new AuthApi(sharedApiConfig.endpoints.auth);
      const { token, refreshToken } = await authApi.login(email, password);
      Session.shared().set(token, refreshToken);
      Apis.shared().setAuthentication(token, refreshToken);
      setLoading(false);
      setError("");
      navigate(redirectUrl ?? "/", { state: { token, refreshToken } });
    } catch (e: any) {
      setLoading(false);
      setError("Please check email and password");
    }
  }, [navigate, password, email, redirectUrl]);

  const toggleRememberMe = () => {
    let providerName: string = EphemeralStorageProvider.name;
    let newRemember = !remember;
    if (newRemember) {
      providerName = PersistentStorageProvider.name;
    }
    Session.changeStorageProvider(Session.shared(), providerName);
    setRemember(newRemember);
  };

  return (
    <Paper sx={{ height: "100%" }}>
      <Box
        sx={{ display: "flex", direction: "column", justifyContent: "center", alignItems: "center", height: "100%" }}
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Container maxWidth="xs">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                Sign in
              </Typography>
              <Divider sx={{ mt: 2, mb: 2, width: "100%" }} />
              <Typography component="h3" variant="h6" sx={{ mb: 1 }}>
                Sign in to the Canucci Analysis platform.
              </Typography>
              {canRemember && (
                <FormControlLabel
                  onChange={toggleRememberMe}
                  checked={remember}
                  sx={{ mt: 1, mb: 0 }}
                  control={<Checkbox color="primary" />}
                  label="Stay signed in"
                />
              )}
              <Divider sx={{ mt: 2, mb: 3, width: "100%" }} />
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  error={!!error}
                  onChange={(e: any) => {
                    setEmail(e.target.value);
                  }}
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  error={!!error}
                  onChange={(e: any) => {
                    setPassword(e.target.value);
                  }}
                  autoComplete="current-password"
                />
                <LoaderButton
                  loading={loading}
                  title="Sign In"
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  size="large"
                />
              </Box>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                {error && (
                  <Typography sx={{ mt: 2 }} color="error">
                    Login failed: {error}
                  </Typography>
                )}
                {(forgotPasswordLink || signupLink) && <LoginFooter />}
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Paper>
  );
}

// TODO: Remove this once we have a proper login flow and use the multi provider login
type LoginType = "multi" | "password";
const loginType: LoginType = "password";

export function LoginPage() {
  const { uiTheme } = useApplication();
  return (
    <ApplicationThemeProvider uiTheme={uiTheme}>
      {loginType === "multi" ? <MultiProviderLogin canRemember={true} /> : <PasswordOnlyLogin canRemember={true} />}
    </ApplicationThemeProvider>
  );
}
