import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import useContainerDimensions from "./util/useContainerDimensions";
import { deepMerge } from "./util/object";
import { ConnectionVisualProps, defaultTreeVisualProps, OptionalTreeVisualProps, TreeVisualProps } from "./types";
import { calcZoomTranslateTopCenter, getBounds, wrap } from "./util/svg";
import { Box } from "@mui/material";

type ChartProps<T> = {
  root: d3.HierarchyNode<T> | null;
  ui?: OptionalTreeVisualProps;
  onShowDetails?: (item: T, x: number, y: number) => void;
  collapseFromDepth?: number;
};

type HierarchyNode = any;

const renderNodes = (svg: any, root: any, vprops: TreeVisualProps, px?: number, py?: number) => {
  const nodes = svg.selectAll(".node")
    .data(root.descendants(), (d: any) => d.data.data.id);

  // setup exit behavior (incl transition)
  nodes
    .exit()
    .transition()
    .duration(vprops.transitionDuration)
    .style("opacity", 0.0)
    .attr("transform", (d: HierarchyNode) => `translate(${px}, ${py})`)
    .remove();

  // setup enter behavior
  const nodesEnter = nodes
    .enter()
    .append("g")
    .style("opacity", 0.0)
    .attr("class", "node");
  nodesEnter
    .append("circle")
    .attr("stroke", vprops?.connection.color ?? "#fff")
    .attr("fill", vprops?.background ?? '#fff')
    .attr("stroke-width", 1);
    //.attr("fill", vprops?.connection.color ?? "#fff");

  nodesEnter
      .attr("transform", (d: HierarchyNode) => `translate(${px ?? d.x}, ${py ?? d.y})`);

  const textElements = nodesEnter.append("g").append("text")
    .text((d: any) => d.data.data.label)
    .attr("x", 0)
    .attr("fill", vprops?.label.color ?? "#000")
    .style("text-anchor", "left")
    .call(wrap, vprops?.label.maxWidth ?? 100, vprops?.label.lineHeight);

  function getLabelTransform(bbox: any, padding: number): string {
    const fx = bbox.x - padding;
    const fy = bbox.y - padding;
    const fw = bbox.width + 2 * padding;
    const fh = bbox.height + 2 * padding;
    const dx = fx + fw * 0.5 + 1;
    const dy = fy + fh;
    return `translate(${-dx}, ${-dy})`;
  }

  textElements.each(function (this: any, d: HierarchyNode) {
    const text = d3.select(this);
    const bbox = text.node().getBBox();
    const padding = vprops?.label.padding ?? 5.0;
    bbox.height = Math.max(18 * 3 + padding, bbox.height);
    d3.select(this.parentNode) // Select the parent <g> element
      .attr("transform", getLabelTransform(bbox, padding))
      .attr("opacity", (d: HierarchyNode) => (!d.data.data.pId ? 0 : 1))
      .insert("rect", "text")
      .attr("x", bbox.x - padding)
      .attr("y", bbox.y - padding + 1)
      .attr("rx", padding)
      .attr("ry", padding)
      .attr("width", bbox.width + 2 * padding)
      .attr("height", bbox.height + 2 * padding)
      .attr("fill", vprops?.label.background ?? '#fff')
      .attr("stroke", vprops?.label.border ?? '#000');
  });

  const mergedNodes = nodesEnter
    .merge(nodes);

  // setup transitions for all nodes
  mergedNodes
    .transition()
    .duration(vprops.transitionDuration)
    .attr("transform", (d: HierarchyNode) => `translate(${d.x}, ${d.y})`)
    .style("opacity", 1.0);

  mergedNodes.selectAll("circle")
    .attr("r", (d: HierarchyNode) => {
      return (d.data.data.pId === undefined || !d.data.children)
        ? undefined 
        : vprops?.connection.radius ?? 5; 
    })
    .on("mouseover", function (this: any) {
      d3.select(this)
        .attr("fill", vprops?.connection.color ?? "#fff");
    })
    .on("mouseout", function (this: any) {
      d3.select(this)
      .attr("fill", vprops?.background ?? '#fff')
    });

  mergedNodes.raise();
};

const renderLinks = (svg: any, root: HierarchyNode, vprops: ConnectionVisualProps, transistionDuration: number, px?: number, py?: number) => {
  const links = svg
    .selectAll(".link")
    .data(root.links(), (d: any) => `${d.source.data.id}-${d.target.data.id}`); // Use a unique key for data join

  const linksExit = links.exit();
  linksExit
    .transition()
    .duration(transistionDuration) // Set duration for fade in
    .attr("d", d3.linkHorizontal()
      .x((d: any) => px ?? 0)
      .y((d: any) => py ?? 0) as any)
    .remove(); 
  
  // Handle entering links
  const linksEnter = links.enter()
    .append("path")
    .attr("opacity", (d:any) => d.source.data.data.id === -1 ? 0 : 1)
    .attr("fill", "none")
    .attr("stroke", vprops?.color ?? "#000")
    .attr("stroke-width", 1)
    .attr("class", "link");
  linksEnter
    .attr("d", d3.linkHorizontal()
      .x((d: any) => px ?? d.x)
      .y((d: any) => py ?? d.y) as any)
  linksEnter
    .merge(links) // Merge new and existing
    .transition()
    .duration(transistionDuration) // Set duration for fade in
    .attr("d", d3.linkHorizontal()
      .x((d: any) => d.x)
      .y((d: any) => d.y) as any);

  links
    .transition()
    .duration(transistionDuration) // Set duration for fade in
    .attr("d", d3.linkHorizontal()
      .x((d: any) => d.x)
      .y((d: any) => d.y) as any);
};

function isCollapsed(n: HierarchyNode) {
  return (!n.children);
}

function toggleChildren(n: HierarchyNode, collapse: boolean) {
    if (collapse && !isCollapsed(n)) {
        n._children = n.children;
        n.children = undefined;
    } else if (!collapse && isCollapsed(n)) {
      n.children = n._children;
      n._children = undefined;
    }
}

function toggleNodesFromDepth(depth: number, root: HierarchyNode, collapse: boolean) {
  function fn(n: HierarchyNode) {
    if (n.children) {
      n.children.forEach(fn);
    }
    if (n.depth > depth) {
      toggleChildren(n, collapse);
    }
  }
  fn(root)
}

export function TreeChart<T>({ root, ui, collapseFromDepth, onShowDetails }: ChartProps<T>) {
  const containerRef = useRef(null);
  const rerenderVal = useRef(0);
  const ref = useRef(null);
  const rootNode = useRef(root);
  const [ rerenderTrigger, setRerenderTrigger ] = useState(0);
  const uiProps = deepMerge<TreeVisualProps>(defaultTreeVisualProps, ui);
  const {width, height} = useContainerDimensions(containerRef);

  // Collapse deep child nodes initially
  useEffect(() => {
    if (collapseFromDepth) {
      toggleNodesFromDepth(collapseFromDepth, rootNode.current, true);
    }
  }, [collapseFromDepth]);

  // Setup zoom
  useEffect(() => {
    if (width && height) {
      const bounds = getBounds(rootNode.current as HierarchyNode);
      const t = calcZoomTranslateTopCenter(bounds, [width, height], 250, 100);
      const z: any = d3.zoom();
      z.on("zoom", (e: any) => d3.select('.wrapper').attr("transform", e.transform));
      z.transform(
        d3.select(ref.current).select("svg"), 
        d3.zoomIdentity.translate(t[0], t[1]).scale(t[2])
      );
      d3.select(ref.current).select("svg").call(z);
    }
  }, [height, rerenderTrigger, width]);

  // Render
  useEffect(() => {
    const root = rootNode.current;
    const nodeWidth = uiProps.label.maxWidth * 1.1 + uiProps.label.padding * 2;
    const treeLayout = d3.tree().nodeSize([nodeWidth, uiProps.nodeHeight]);
    const connUiProps = uiProps.connection;
    const tDuration = uiProps.transitionDuration;

    treeLayout(root as any);

    let svg = d3.select(ref.current).selectAll("svg").data([null]);
    svg.merge(svg.enter().append("svg") as any);
    svg
      .style("background", uiProps.background)
      .attr("width", width)
      .attr("height", height);

    let wrapper = svg.selectAll("g.wrapper").data([null]);
    wrapper = wrapper.merge(wrapper.enter().append("g") as any).attr("class", "wrapper")

    let treeGroup = wrapper.selectAll("g.tree-group").data([null]); // Select existing, or prepare for new
    treeGroup = treeGroup.merge((treeGroup.enter().append("g") as any).attr("class", "tree-group"));


    const handleToggleCollapse = (e: any, d: HierarchyNode) => {
      e.stopPropagation();
      let px = d.x;
      let py = d.y;
      const isCollapsing = !isCollapsed(d);
      toggleChildren(d, isCollapsing);
      treeLayout(root as any);
      if (isCollapsing) {
        px = d.x;
        py = d.y;
      }
      renderLinks(treeGroup, root, connUiProps, tDuration, px, py);
      renderNodes(treeGroup, root, uiProps, px, py);

      treeGroup.selectAll(".node").on("click", handleDetails as any);
      treeGroup.selectAll(".node circle").on("click", handleToggleCollapse as any);
    }

    const handleDetails = (e: any, d: HierarchyNode) => {
      e.stopPropagation();
      e.preventDefault();
      if (onShowDetails) {
        onShowDetails(d.data.data, e.clientX, 0);
      }
    }

    renderLinks(treeGroup, root, connUiProps, tDuration, undefined,  undefined);
    renderNodes(treeGroup, root, uiProps, undefined,  undefined);
       
    treeGroup.selectAll(".node").on("click", handleDetails as any);
    treeGroup.selectAll(".node circle").on("click", handleToggleCollapse as any);
    
    // Hacky way to avoid update cycle
    rerenderVal.current += 1;
    setRerenderTrigger(rerenderVal.current);
  }, [height, onShowDetails, uiProps, width]);

  return <Box ref={containerRef} sx={{width: '100%', height: '100%'}}>
    <div ref={ref} />
  </Box>;
}