export function deepMerge<T>(a: any, b: any) {
  const merge = (na: any, nb: any): any => {
    switch (typeof nb) {
      case "string":
        return nb;
      case "number":
        return nb;
      case "undefined":
        return na;
    }
    const props = [...Object.keys(na ?? {}), ...Object.keys(nb ?? {})];
    const result: any = {};
    props.forEach((p) => {
      p && (result[p] = merge(na?.[p], nb?.[p]));
    });
    return result;
  };
  return merge(a, b) as T;
}
