import { ApiId, OrganizationInfo } from "../apimodels";
import { standardGetOptions, standardPostOptions, throwApiError } from "../helpers";
import { ApiBatchResult, IAuthApi } from "../types";

export class AuthApi implements IAuthApi {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  googleLoginUrl(targetPath?: string): string {
    const url = new URL(this.baseUrl + "/login/google");
    url.searchParams.set("target", targetPath ?? "/");
    return url.toString();
  }

  async listOrganizations(token: string): Promise<ApiBatchResult<OrganizationInfo>> {
    const url = `${this.baseUrl}/organizations`;
    const options = standardGetOptions({ headers: { Authorization: `Bearer ${token}` } });
    const response = await fetch(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    const data = await response.json();
    return data;
  }
  async login(username: string, password: string): Promise<{ token: string; refreshToken: string }> {
    const url = `${this.baseUrl}/login`;
    const options = standardPostOptions({ body: JSON.stringify({ username, password }) });
    const response = await fetch(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    const data = await response.json();
    return { token: data.token, refreshToken: data.refreshToken };
  }

  async tokenRefresh(token: string, refreshToken: string): Promise<string> {
    const url = `${this.baseUrl}/refresh`;
    const options = standardPostOptions({ body: JSON.stringify({ token, refreshToken }) });
    const response = await fetch(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    const data = await response.json();
    return data.token;
  }

  async exchangeToken(token: string, organizationId: ApiId): Promise<{ token: string; refreshToken: string }> {
    const url = `${this.baseUrl}/exchange`;
    const options = standardPostOptions({ body: JSON.stringify({ token, organization_id: organizationId }) });
    const response = await fetch(url, options);
    if (!response.ok) {
      throwApiError(response.status);
    }
    const data = await response.json();
    return { token: data.token, refreshToken: data.refreshToken };
  }
}
