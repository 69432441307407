import {
  Box,
  Button,
  ButtonPropsColorOverrides,
  ButtonPropsVariantOverrides,
  CircularProgress,
  SxProps,
  Theme,
} from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { ReactNode } from "react";

type Props = {
  loading: boolean;
  disabled?: boolean;
  title: string;
  className?: string;
  size?: "small" | "medium" | "large";
  icon?: ReactNode;
  variant?: OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides>;
  color?: OverridableStringUnion<
    "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    ButtonPropsColorOverrides
  >;
  sx?: SxProps<Theme>;
  onClick: () => void;
};

export function LoaderButton(props: Props) {
  const { loading, disabled, title, onClick, className, color, sx, icon, size } = props;
  const iconSx: SxProps<Theme> = icon ? { mx: 0 } : {};
  function onClickButton(e: any) {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  }

  return (
    <Button
      variant={props.variant}
      color={color}
      size={size}
      startIcon={icon}
      className={className}
      type="submit"
      disabled={disabled || loading}
      fullWidth
      sx={{ mt: 3, mb: 2, ...sx }}
      onClick={onClickButton}
    >
      <Box sx={{ position: "relative", top: "1px", ...iconSx }}>{title}</Box>
      {loading && (
        <Box sx={{ height: "100%", ml: "12px", display: "flex", flexDirection: "row" }}>
          <CircularProgress size={20} color="secondary" />
        </Box>
      )}
    </Button>
  );
}
