import * as d3 from "d3";

type Bounds = {
  xMin: number;
  xMax: number;
  yMin: number;
  yMax: number;
}

export function getBounds(root: d3.HierarchyNode<any>): Bounds {
  let xMin = Infinity;
  let xMax = -Infinity;
  let yMin = Infinity;
  let yMax = -Infinity;

  root.each((d: any) => {
    if (d.x < xMin) xMin = d.x;
    if (d.x > xMax) xMax = d.x;
    if (d.y < yMin) yMin = d.y;
    if (d.y > yMax) yMax = d.y;
  });
  return { xMin, xMax, yMin, yMax };
}

export function calcZoomTranslateCenter(bounds: Bounds, view: [number, number], margin: number = 0): [number, number, number] {
  const xMid = (bounds.xMin + bounds.xMax) * 0.5;
  const yMid = (bounds.yMin + bounds.yMax) * 0.5;
  const width = (bounds.xMax - bounds.xMin) + margin;
  const height = (bounds.yMax - bounds.yMin) + margin;
  const scale = Math.min(view[0] / width, view[1] / height);
  return [(view[0] / 2) - (xMid * scale), (view[1] / 2) - (yMid * scale), scale];
}

export function calcZoomTranslateTopCenter(bounds: Bounds, view: [number, number], margin: number = 0, yOffset: number = 0): [number, number, number] {
  const xMid = (bounds.xMin + bounds.xMax) * 0.5;
  const width = (bounds.xMax - bounds.xMin) + margin;
  const height = (bounds.yMax - bounds.yMin) + margin;
  const scale = Math.min(view[0] / width, view[1] / height);
  return [(view[0] / 2) - (xMid * scale), yOffset * scale, scale];
}

export function wrap(text: any, width: number, lineHeight: string = "18px") {
  text.each(function (this: SVGTextElement) {
    const text = d3.select(this);
    const words = text.text().split(/-/).reverse();
    let word;
    let line: string[] = [];
    const x = text.attr("x");
    let tspan = text
      .text(null)
      .append("tspan")
      .attr("x", x)
      .attr("dy", lineHeight);
    while ((word = words.pop())) {
      word = word.charAt(0).toUpperCase() + word.substring(1);
      line.push(word);
      tspan.text(line.join(" "));
      const n = tspan.node();
      if (n && n.getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", x)
          .attr("dy", lineHeight)
          .attr("text-anchor", "start")
          .text(word);
      }
    }
  });
}
