import { useEffect, useState } from "react";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { Apis } from "../../../api/apis";
import { DatasetArtifact, ExplorationModel, ProjectModel } from "../../../api/apimodels";
import { Container, SxProps, Theme } from "@mui/material";
import { TreeView } from "./TreeView";
import { HierarchyFiles } from "./types";

type ExplorationHierarchyTabProps = {
  loading: boolean, 
  setLoading: (v:boolean) => void, 
  sx?: SxProps<Theme>,
  artifacts: DatasetArtifact[] | undefined,
};

export function ExplorationHierarchyTab(props: ExplorationHierarchyTabProps) {
  const { exploration } = useLoaderData() as { project: ProjectModel, exploration: ExplorationModel };
  const project = useRouteLoaderData('project') as ProjectModel;
  const [urls, setUrls] = useState<[string, string, string] | undefined>();

  useEffect(() => {
    async function fn() {
      if (!exploration.dataset) {
        return;
      }
      const { dataset } = exploration;
      const urls = HierarchyFiles.map((f: string) => {
        return Apis.shared().project.datasetArtifactDownloadUrl(project.id ?? "", dataset!.id!, f);
      });
      const links = await Promise.all(urls.map((u) => (Apis.shared().project.signedLink(u)))) as [string, string, string];
      setUrls(links);
    }
    fn();
  }, [exploration, project.id]);

  return (
    <Container maxWidth={false} sx={{ mt: 4, mb: 4, width: '100%', height: '100%'}}>
      {urls && (<TreeView urls={urls} />)}
    </Container>
  );
};