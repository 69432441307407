import ErrorIcon from '@mui/icons-material/Error';
import { Box, Container, Grid } from "@mui/material";
import { useState } from "react";
import { useLoaderData, useLocation, useNavigate, useRouteLoaderData } from "react-router-dom";
import { DatasetModel, ProjectModel } from "../../api/apimodels";
import { Apis } from "../../api/apis";
import { ApiBatchResult, ApiError, ApiGeneralError } from "../../api/types";
import ResourceCreateForm from "../../components/common/ResourceCreateForm";
import ResourceList from "../../components/common/ResourceList";
import { sortedResource } from "../../util/sorting";
import { DatasetPage as DatasetPageInfo } from "../PageInfo";

export function CreateDatasetForm() {
  const project = useRouteLoaderData('project') as ProjectModel; // Let project load
  const api = Apis.shared().project;
  const navigate = useNavigate();
  const location = useLocation();
  const [ pendingDataset, setPendingDataset ] = useState<DatasetModel | undefined>(undefined);
  const [ error, setError ] = useState<Error | undefined>();

  // First we create a dataset container, in the next step we upload the data
  // While we could consider doing uploads with both metadata and raw data in the same request (possible with multipart uploads for example), 
  // I still thing it is fair to allow for datasets to be empty. E.g. if a customer asks for the data to be deleted. 
  // For now, we handle the two step upload process with keeping the created dataset id around in case the data validation fails. 
  // We also allow for datasets without raw data to be deleted so the dataset list can be kept short. 

  const onCreateDataset = async (name: string, description: string, filename?: string, data?: ArrayBuffer) => {
    const dataset: DatasetModel = { name, description };
    setError(undefined);
    if (project?.id) {
      let pending = pendingDataset;
      if (pending?.id) {
        pending = await api.updateDataset(project.id, pending.id, name, description);
      } else {
        pending = await api.createDataset(project.id, dataset);
        setPendingDataset(pending);
      }
      try {
        await api.uploadDatasetContent(project.id, pending.id!, filename!, data!);
        setPendingDataset(undefined);
        navigate(location, {replace: true});
      } catch (e) {
        if (e instanceof ApiGeneralError) {
          const columns = e.details!.columns as {'column': string, 'problem': string}[];
          const numUnnamed = e.details!.num_unnamed_columns as number;
          const errors = columns.map(({column, problem}) => (`${column}: ${problem}`));
          if (numUnnamed > 0) {
            errors.push(`One or more columns are unnamed.`);
          }
          setError(new Error(`CSV file column validation failed:\n${errors.join("\n  ")}`));
          throw e;
        } else if (e instanceof ApiError) {
          setError(new Error(`Upload failed, status code ${e.statusCode}`));
          console.error("Upload of dataset failed, statusCode", e.statusCode);
          throw e;
        } else {
          console.error(e);
        }
      }
    }
    return;
  };
  
  return (
    <ResourceCreateForm
      title="Add dataset"
      icon={DatasetPageInfo.menuIcon}
      embedded={false}
      error={error}
      onCreate={onCreateDataset}
      resourceNameLabel={"Dataset name"}
      resourceDescriptionLabel={"Dataset description"}
      uploadButtonLabel={"Upload CSV"}
      createButtonLabel={"Create dataset"}
    />
  );
}

export default function DatasetsPage() {
  const project = useRouteLoaderData('project') as ProjectModel;
  const navigate = useNavigate();
  const data = useLoaderData() as ApiBatchResult<DatasetModel>;
  const items = sortedResource(data.items, true);
  
  function onSelectDataset(id: number) {
    navigate("./" + id);
  }
  
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {(!data.empty) && (
              <Grid item xs={12} md={8} lg={8}>
                <ResourceList
                  onSelectResource={onSelectDataset}
                  title="Datasets"
                  icon={DatasetPageInfo.menuIcon}
                  iconFn={(item) => (item.filename !== null ? DatasetPageInfo.menuIcon : <ErrorIcon color="error" />)}
                  isLoadingFn={() => (false)}
                  items={items}
                  createLabel="Add dataset"
                  forceExpand={project?.latest_datasets?.length === 0}
                />
              </Grid>
            )}
            <Grid item xs={12} md={4} lg={4}>
              <CreateDatasetForm />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
