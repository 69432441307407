import { DatasetModel, ProcessorDatasetParameter, ProcessorParameterType } from "../../api/apimodels";
import ResourceMultiSelect from "../common/ResourceMultiSelect";
import ResourceSelect from "../common/ResourceSelect";

type Props = {
  param: ProcessorDatasetParameter;
  datasets: DatasetModel[];
  value: number[];
  disabled?: boolean;
  onChange: (name: string, value: number[]) => void;
};

export default function DatasetParameterField(props: Props) {
  const { param, datasets, value, disabled, onChange } = props;
  
  const handleChangeSingle = (dataset: DatasetModel) => {
    onChange(param.name, [dataset.id!]);
  };

  const handleChangeMulti = (datasets: DatasetModel[]) => {
    onChange(param.name, datasets.map((d) => d.id!));
  };

  return (
    param.multiple ? (
      <ResourceMultiSelect
        className="resource-select"
        label={(param.displayName ?? param.name)}
        disabled={disabled}
        selected={datasets.filter((d) => value.includes(d.id!))}
        resources={datasets}
        onChange={handleChangeMulti}
      />
    ) : (
      <ResourceSelect
        className="resource-select"
        label={(param.displayName ?? param.name)}
        disabled={disabled}
        selected={datasets.find((d) => d.id === value[0])}
        resources={datasets}
        onChange={handleChangeSingle}
      />
    )
  );
}
