import { DataState } from "../pages/explorations/data/DataState";

export type ApiId = number | string;

export enum ResultState {
  completed = "completed",
  preparing = "preparing",
  running = "running",
  error = "error",
}

export interface NamedItemModel {
  id?: number;
  name: string;
  description: string;
}

export interface StandardItemModel extends NamedItemModel {
  created_at?: string;
}

export interface OrganizationInfo {
  id: number;
  name: string;
  role: string;
}

export interface ProjectModel extends StandardItemModel {
  subtitle?: string;
  latest_datasets?: DatasetModel[] | null;
  latest_explorations?: ExplorationModel[] | null;
  prompts?: PromptModel[] | null;
  deletable: boolean;
}

export interface ProjectCreateModel {
  name: string;
  subtitle?: string;
  description?: string;
}

export const LabelColumnPrefix = "label_";

export interface DatasetArtifact extends StandardItemModel {
  size: number;
}

export interface DatasetModel extends StandardItemModel {
  filename?: string | null;
  labels_filename?: string | null;
  artifacts?: DatasetArtifact[];
  root_id?: number;
  parent_id?: number;
  job_id?: number;
  job?: JobInfoModel;
  columns?: string[];
}

export interface LabelValue {
  description: string;
  name: string;
  order: number;
  value: any;
}

export interface LabelMapping {
  description: string;
  id: string;
  name: string;
  order: number;
  type: string;
  values?: LabelValue[];
}

export type LabelMappings = Record<string, LabelMapping>;

export interface JobStatus {
  state: string;
  progress: number;
  time: string;
  message: string;
}

export interface JobInfoModel extends StandardItemModel {
  status?: JobStatus;
  completed: boolean;
  related_id: number;
  related_entity: string;
  processor_id: number;
  parameters?: string;
}

export function isJobFailed(job: JobInfoModel, maxPendingTime: number = 60 * 60): boolean {
  return !job.completed && (Date.now() - new Date(job.created_at!).getTime()) / 1000 > maxPendingTime;
}

// PROCESSOR

export enum ProcessorParameterType {
  INTEGER = "INTEGER",
  ENUM = "ENUM",
  INTEGER_ENUM = "INTEGER_ENUM",
  FLOAT = "FLOAT",
  BOOLEAN = "BOOLEAN",
  STRING = "STRING",
  KVLIST = "KVLIST",
  DATASET = "DATASET",
}

interface ProcessorBaseParameter {
  type: ProcessorParameterType;
  name: string;
  displayName?: string;
  description?: string;
  width?: number;
}

export interface ProcessorIntegerParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.INTEGER;
  defaultValue: number;
  minValue?: number;
  maxValue?: number;
  step?: number;
}

export interface ProcessorEnumParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.ENUM;
  defaultValue: string;
  values: string[];
}

export interface ProcessorIntegerEnumParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.INTEGER_ENUM;
  defaultValue: string;
  values?: number[];
  minValue?: number;
  maxValue?: number;
}

export interface ProcessorStringParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.STRING;
  defaultValue: string;
  minRows?: number;
  multiline?: boolean;
}

export interface ProcessorKVListParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.KVLIST;
  defaultValue: [string, string][];
  value: [string, string][];
  keyLabel: string;
  valueLabel: string;
}

export interface ProcessorFloatParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.FLOAT;
  defaultValue: number;
  minValue?: number;
  maxValue?: number;
  significatDigits?: number;
}

export interface ProcessorBooleanParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.BOOLEAN;
  labels?: [string, string];
  defaultValue: boolean;
}

export interface ProcessorDatasetParameter extends ProcessorBaseParameter {
  type: ProcessorParameterType.DATASET;
  defaultValue: number[];
  multiple: boolean;
  minSelections?: number;
  maxSelections?: number;
}

export type ProcessorParameterDescriptor =
  | ProcessorIntegerParameter
  | ProcessorIntegerEnumParameter
  | ProcessorFloatParameter
  | ProcessorEnumParameter
  | ProcessorBooleanParameter
  | ProcessorStringParameter
  | ProcessorKVListParameter
  | ProcessorDatasetParameter;

type ProcessorColumnDescriptor = {
  input: string[];
  output: string[];
};

export interface ProcessorManifest {
  formatters: Record<string, any>;
  columns?: ProcessorColumnDescriptor;
  parameters: [ProcessorParameterDescriptor];
}

export interface ProcessorModel extends StandardItemModel {
  filename?: string | null;
  manifest: ProcessorManifest;
}

// PROMPT

export interface PromptModel extends StandardItemModel {
  type: string;
  text: string;
}

export interface ExplorationFilterClause {
  id?: string;
  operator: string;
  enabled: boolean;
  ids: string[];
}

export const emptyExplorationFilterClause: ExplorationFilterClause = Object.freeze({
  operator: "or",
  enabled: true,
  ids: [],
});

export interface ExplorationFilterClauses {
  operator: string;
  enabled: boolean;
  clauses: ExplorationFilterClause[];
}

export const emptyExplorationFilterClauses: ExplorationFilterClauses = Object.freeze({
  operator: "or",
  enabled: true,
  clauses: [],
});

export interface ExplorationSampleFilter {
  operator: string;
  enabled: boolean;
  ids: string[];
}

export interface ExplorationFilter {
  topic?: ExplorationFilterClauses;
  theme?: ExplorationFilterClause;
  label?: ExplorationFilterClauses;
  sample?: ExplorationSampleFilter;
}

export function cleanupFilter(filter: ExplorationFilter | undefined): ExplorationFilter | undefined {
  if (!filter) {
    return undefined;
  }
  let { topic, theme, sample, label } = { ...filter };
  if ((theme?.ids ?? []).length === 0) {
    theme = undefined;
  }
  if (topic) {
    const { clauses } = topic;
    topic.clauses = [...clauses.filter((c) => c.ids.length > 0)];
    if (topic.clauses.length === 0) {
      topic = undefined;
    }
  }
  if (label) {
    const { clauses } = label;
    label.clauses = [...clauses.filter((c) => c.ids.length > 0)];
    if (label.clauses.length === 0) {
      label = undefined;
    }
  }
  if (!theme && !topic && !label) {
    return sample ? { sample } : undefined;
  }
  return { topic, theme, sample, label };
}

export const emptyExplorationFilter: ExplorationFilter = Object.freeze({
  topic: { ...emptyExplorationFilterClauses },
  theme: { ...emptyExplorationFilterClause },
  label: { ...emptyExplorationFilterClauses },
});
export interface ExplorationSelection extends StandardItemModel {
  filter: ExplorationFilter;
  data_state?: DataState;
}

export interface ExplorationModel extends StandardItemModel {
  dataset_id: number;
  dataset?: DatasetModel;
  selections: ExplorationSelection[];
}

type TextStatistics = {
  total: number;
  mean: number;
  bins: [number, number][];
};

type WordStatistics = {
  text: TextStatistics;
  original: TextStatistics;
};
export interface DataStatisticsResult {
  topics: [string, number][];
  themes: [string, number][];
  labels?: [string, [string, number][] | undefined][];
  theme_topics?: [string, [string, number][] | undefined][];
  heatmap?: [string, [string, number][] | undefined][];
  filtered_rows: number;
  total_rows: number;
  original_texts?: number;
  word?: WordStatistics;
}

export interface DataHeatmapResult {
  heatmap?: {
    x_axis: [string, string];
    y_axis: [string, string];
    items: [string, [string, number][] | undefined][];
  };
}

export interface DataSamplesResult {
  rows: Record<string, any>[];
  start: number;
  count: number;
  total_count: number;
}

export interface LangTextSummaryStatistics {
  estimated_token_count: number;
  estimated_cost_usd: number;
}

export interface LangTextAggregateOptions {
  temperature: number;
  model: string;
  prompt: string;
}

export interface LangTextSummaryAvailableOptions {
  models: string[];
  temperature: [number, number];
  defaults: LangTextAggregateOptions;
}

export interface LangTextSummmary {
  text: string;
}

export interface JobModel {
  id: string;
  log_url: string;
}
