import { Backdrop, Box, Breadcrumbs, CircularProgress, Fade, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useState } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { DatasetArtifact, ExplorationModel } from "../../api/apimodels";
import { Nav } from "../../components/common/Nav";
import * as PageInfo from "../../pages/PageInfo";
import { ExplorationDetailsDataTab } from "./data/ExplorationDataTab";
import { ExplorationInfoTab } from "./info/ExplorationInfoTab";
import { ExplorationCompareTab } from "./compare/ExplorationCompareTab";
import { HierarchyFiles } from "./hierarchy/types";
import { ExplorationHierarchyTab } from "./hierarchy/ExplorationHierarchyTab";

function hasHierarchy(artifacts: DatasetArtifact[] | undefined): boolean {
  if (!artifacts) {
    return false;
  }
  const artifactSet = new Set<string>(artifacts.map((a) => a.name));
  return (!!HierarchyFiles.find((a) => artifactSet.has(a)));
}

export const ExplorationDetailsPage = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { exploration } = useLoaderData() as { exploration: ExplorationModel};
  const section = searchParams.get('section') ?? 'data';
  const { dataset } = exploration;
  const artifacts = dataset?.artifacts;

  const setSection = (section: string) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('section', section);
    setSearchParams(newParams);
  };

  return  <Box sx={{ display: "flex", height: '100%' }}>
  <Box
    component="main"
    sx={{
      flexGrow: 1,
      overflow: "auto",
    }}
  >
    <Stack direction="row" justifyContent="space-between" alignItems="baseline">
      <Breadcrumbs aria-label="breadcrumb" sx={{ ml: 3, mt: 5 }}>
        <Nav to="./..">{PageInfo.ExplorationPage.name}</Nav>
        <Typography color="text.primary">{exploration.name}</Typography>
      </Breadcrumbs>
      <ToggleButtonGroup size="small" sx={{mr: 3}} exclusive value={section} onChange={(e, s) => setSection(s)}>
        <ToggleButton value="info">Info</ToggleButton>
        {/*}
        <ToggleButton value="compare">Compare</ToggleButton>
        */}
        {hasHierarchy(artifacts) && (<ToggleButton value="hierarchy">Hierarchy</ToggleButton>)}
        <ToggleButton value="data">Data</ToggleButton>
      </ToggleButtonGroup>
    </Stack>

    {section === 'data' && (
      <ExplorationDetailsDataTab loading={loading} setLoading={setLoading} />
    )}
    {section === 'info' && (
      <ExplorationInfoTab loading={loading} setLoading={setLoading} />
    )}
    {section === 'hierarchy' && (
      <ExplorationHierarchyTab loading={loading} setLoading={setLoading} artifacts={artifacts} />
    )}    
    {section === 'compare' && (
      <ExplorationCompareTab loading={loading} setLoading={setLoading} selections={exploration.selections}/>
    )}
    </Box>
      <Fade in={!!loading} timeout={{enter: 3000, exit: 200 }}>
        <Backdrop open={!!loading}>
          <CircularProgress color="inherit" sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} />
        </Backdrop>
      </Fade>
    </Box>
};