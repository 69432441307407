import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { OrganizationActionTypes, useOrganization, useOrganizationDispatch } from "../../state/organizationstate";
import { useApplication } from "../../state/applicationstate";
import { Apis } from "../../api/apis";
import { Session } from "../auth/session";

export function OrganizationSelector() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const organization = useOrganization();
  const organizationDispatch = useOrganizationDispatch();
  const { organizations } = useApplication();
  const authApi = Apis.shared().auth;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (orgId: number) => {
    const org = organizations.find((org) => org.id === orgId);
    if (Session.shared().token) {
      authApi.exchangeToken(Session.shared().token!, org!.id).then(({ token, refreshToken }) => {
        Session.shared().set(token, refreshToken);
        organizationDispatch({
          type: OrganizationActionTypes.setOrganization,
          id: org!.id,
          name: org!.name,
        });
        window.location.href = "/";
        handleClose();
      });
    }
  };

  return (
    <>
      <Button variant="text" onClick={handleClick}>
        [{organization.name}]
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {(organizations ?? []).map((org) => (
          <MenuItem key={org.id} onClick={() => handleSelect(org.id)} selected={org.id === organization.id}>
            {org.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
