import OverviewIcon from "@mui/icons-material/Domain";
import ProjectIcon from "@mui/icons-material/Folder";
import DatasetsIcon from "@mui/icons-material/Dataset";
import ProcessorsIcon from "@mui/icons-material/Memory";
import ExplorationIcon from "@mui/icons-material/Search";
import PromptIcon from "@mui/icons-material/Notes";
import { ApiId } from "../api/apimodels";

export enum PageType {
  organization,
  project,
}

export type PageInfo = {
  slug: string;
  name: string;
  menuName: string;
  paramId: string | undefined;
  type: PageType;
  menuIcon: any;
};

export const OverviewPage: PageInfo = {
  name: "All projects",
  menuName: "All projects",
  slug: "overview",
  paramId: undefined,
  type: PageType.organization,
  menuIcon: <OverviewIcon />,
};

export const ProjectPage: PageInfo = {
  name: "Project",
  menuName: "Project",
  slug: "project",
  paramId: "projectId",
  type: PageType.project,
  menuIcon: <ProjectIcon />,
};

export const DatasetPage: PageInfo = {
  name: "Dataset",
  menuName: "Dataset",
  slug: "dataset",
  paramId: "datasetId",
  type: PageType.project,
  menuIcon: <DatasetsIcon />,
};

export const ProcessorPage: PageInfo = {
  name: "Model",
  menuName: "Model",
  slug: "processor",
  paramId: "processorId",
  type: PageType.project,
  menuIcon: <ProcessorsIcon />,
};

export const ExplorationPage: PageInfo = {
  name: "Exploration",
  menuName: "Exploration",
  slug: "exploration",
  paramId: "explorationId",
  type: PageType.project,
  menuIcon: <ExplorationIcon />,
};

export const PromptPage: PageInfo = {
  name: "Prompt",
  menuName: "Prompt",
  slug: "prompt",
  paramId: "promptId",
  type: PageType.project,
  menuIcon: <PromptIcon />,
};

export function overviewPath() {
  return "/" + OverviewPage.slug;
}

export function projectPath(id: ApiId | string) {
  const pieces = [ProjectPage.slug, id];
  return pieces.join("/");
}

export function datasetPath(projectId: ApiId | string, datasetId?: ApiId | string, paramPrefix = "") {
  const pieces = [ProjectPage.slug, paramPrefix + projectId, DatasetPage.slug, paramPrefix + datasetId];
  if (!datasetId) {
    pieces.pop();
  }
  return pieces.join("/");
}

export function processorPath(projectId: ApiId | string, processorId?: ApiId | string, paramPrefix = "") {
  const pieces = [ProjectPage.slug, paramPrefix + projectId, ProcessorPage.slug, paramPrefix + processorId];
  if (!processorId) {
    pieces.pop();
  }
  return pieces.join("/");
}

export function explorationPath(projectId: ApiId | string, explorationId?: ApiId | string, paramPrefix = "") {
  const pieces = [ProjectPage.slug, paramPrefix + projectId, ExplorationPage.slug, paramPrefix + explorationId];
  if (!explorationId) {
    pieces.pop();
  }
  return pieces.join("/");
}

export function promptPath(projectId: ApiId | string, promptId?: ApiId | string, paramPrefix = "") {
  const pieces = [ProjectPage.slug, paramPrefix + projectId, PromptPage.slug, paramPrefix + promptId];
  if (!promptId) {
    pieces.pop();
  }
  return pieces.join("/");
}

export const TopLevelPages = [OverviewPage, ProjectPage, ExplorationPage, DatasetPage, ProcessorPage, PromptPage];

export const OrganizationPages = [OverviewPage];

export const ProjectPages = [ProjectPage, ExplorationPage, DatasetPage, ProcessorPage, PromptPage];
