import { useEffect, useMemo, useState } from "react";
import * as d3 from "d3";
import { TreeChart } from "./TreeChart";
import { parse } from 'csv-parse/browser/esm/sync';
import { Box, capitalize, Typography } from "@mui/material";

type Props = {
  urls: [string, string, string];
};
interface NodeData {
  id: number;
  pId?: number;
  label: string;
  description: string;
  depth: number;
};

type InfoBoxProps = {
  id: number;
  title: string;
  details: string;
  onClose?: (id: number) => void;
};


function InfoBox(props: InfoBoxProps) {
  const { id, title, details, onClose } = props;
  return <Box sx={{
    position: 'absolute', 
    textAlign: 'left', 
    top: '0.0rem', 
    zIndex: 1000,
    right: '0.5rem', 
    border: '1px solid #90caf9', 
    width: '20rem', 
    borderRadius: '0.625rem', 
    backgroundColor: '#222', 
    color: '#fff', 
    padding: '1.25rem 1.25rem 1.5rem 1.25rem', 
  }} onClick={() => (onClose?.(id))

  }>
    <Typography variant="h6" sx={{mb: '0.5rem'}}>{capitalize(title.split('-').join(' '))}</Typography>
    <Typography variant="body2">{details}</Typography>
  </Box>;
}

function nodeDataFromCSV(records: string[][]): NodeData[] {
  const nodeList = records.map((row: any) => {
    const {id, parent_id, label, depth, definition} = row;
    const pId = parseInt(parent_id);
    return {
      id: parseInt(id),
      pId: isNaN(pId) ? undefined : pId,
      label,
      depth: parseInt(depth),
      description: definition
    };
  });
  return nodeList;
}

export function TreeView(props: Props) {
  const { urls } = props;
  const [root, setRoot] = useState<d3.HierarchyNode<any> | null>(null);
  const [ infoBoxes, setInfoBoxes ] = useState<InfoBoxProps[]>([]);

  function onCloseDetails(id: number) {
    setInfoBoxes(infoBoxes.filter((b) => b.id !== id));
  }

  const treeChart = useMemo(() => {
    return <TreeChart<NodeData> root={root} collapseFromDepth={1} onShowDetails={onShowDetails} />;
  }, [root]);

  useEffect(() => {
    const fn = async () => {
      const csvData = await fetch(urls[0]);
      const nodeList = nodeDataFromCSV(parse(await csvData.text(), { columns: true }));
      const sroot: any = d3
        .stratify()
        .id((d: any) => d.id)
        .parentId((d: any) => d.pId)(nodeList);
      setRoot(d3.hierarchy(sroot));
    };
    fn();
  }, [urls]);

  function onShowDetails(node: NodeData, x: number, y: number) {
      setInfoBoxes([{id: node.id, title: node.label, details: node.description}]);
  }

  return (
      <Box sx={{position: 'relative', width: '100%', height: '100%' }}>
        {root && (treeChart)}
        {infoBoxes.map((b) => (
          <InfoBox key={b.id} id={b.id} title={b.title} details={b.details} onClose={onCloseDetails} />
        ))}
    </Box>
  );
}
