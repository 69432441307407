import * as d3 from "d3";

export type LabelNode = d3.Selection<
  d3.BaseType | HTMLAnchorElement,
  d3.HierarchyNode<any>,
  SVGGElement,
  unknown
>;

export type OptionalLabelVisualProps = {
  background?: string;
  color?: string;
  border?: string;
  borderWidth?: number;
  maxWidth?: number;
  padding?: number;
  lineHeight?: number;
};

export type LabelVisualProps = {
  background: string;
  color: string;
  border: string;
  borderWidth: number;
  maxWidth: number;
  padding: number;
  lineHeight: number;
};

export type ConnectionVisualProps = {
  color: string;
  radius: number;
}

export type OptionalConnectionVisualProps = {
  color?: string;
  radius?: number;
}

export type OptionalTreeVisualProps = {
  background?: string;
  label?: OptionalLabelVisualProps;
  connection?: OptionalConnectionVisualProps;
  nodeHeight?: number;
  transitionDuration?: number;
};

export type TreeVisualProps = {
  background: string;
  label: LabelVisualProps;
  connection: ConnectionVisualProps;
  nodeHeight: number;
  transitionDuration: number;
};

export const defaultTreeVisualProps: TreeVisualProps = {
  background: "#121212",
  label: {
    background: "#222",
    color: "#eee",
    border: "#537a8b",
    borderWidth: 2,
    maxWidth: 150,
    padding: 10,
    lineHeight: 20,
  },
  connection: {
    color: "#90caf9",
    radius: 15,
  },
  nodeHeight: 200,
  transitionDuration: 400,
};

export const HierarchyFiles = ["tree.csv"];